import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import APIS from "../../apiS"
import APID from "../../apiD"
import { withTranslation } from "react-i18next"

const EditAffectStage = props => {
  // lang
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [arrayCycle, setArrayCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [arrayDiplome, setArrayDiplome] = useState([])
  const [selectDiplome, setSelectDiplome] = useState("")
  const [arrayNiv, setArrayNiv] = useState([])
  const [selectNiv, setSelectNiv] = useState("")
  const [specArray, setSpecArray] = useState([])
  const [selectSpec, setSelectSpec] = useState("")
  const [arrayGroupe, setArrayGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [arrayEtudiant, setArrayEtudiant] = useState([])
  const [selectEtud, setSelectEtud] = useState([])
  const [arrayEncadrant, setArrayEncadrant] = useState([])
  const [selectEncad, setSelectEncad] = useState("")
  const [arrayEse, setArrayEse] = useState([])
  const [selectEse, setSelectEse] = useState("")
  const [arraySujet, setArraySujet] = useState([])
  const [idEtud, setIdEtud] = useState("")
  const [idEncad, setIdEncad] = useState("")
  const [selectSujet, setSelectSujet] = useState("")
  const [modal, setModal] = useState(false)
  const [arrayEtatStage, setEtatStage] = useState([
    { label: "Obligatoire", value: 1 },
    { label: "Volontaire", value: 2 },
  ])
  const [selectEtatStage, setSelectEtatStage] = useState("")
  //
  useEffect(async () => {
    // encadrant
    const resS = await API.get("encadreur/list_encadreur_select").then(resS => {
      setArrayEncadrant(resS.data.encadreurs)
    })
    // entreprise
    const resA = await API.post("entreprise/select", {
      lang: lng,
    }).then(resA => {
      setArrayEse(resA.data.Entreprise)
    })
    // cycle
    const resN = await APID.get("cycle/select").then(resN => {
      setArrayCycle(resN.data.Cycle)
    })
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var tab = array[1]
    var tabId = tab.split("/")
    var id = tabId[0]
    var id_etudiant = tabId[1]
    var id_encadrant = tabId[2]
    //
    setIdEtud(id_etudiant)
    setIdEncad(id_encadrant)
    //
    const resL = await API.post("affectation/getById", {
      id: id,
      id_etudiant: id_etudiant,
      id_encadrant: id_encadrant,
    }).then(resL => {
      setSelectEtud(resL.data.Affectation.etudiant)
      setSelectEncad(resL.data.Affectation.encadrant)
      setSelectEse(resL.data.Affectation.entreprise)
      setSelectSujet(resL.data.Affectation.stage)
      setSelectEtatStage(resL.data.Affectation.etat)
      var etudId = resL.data.Affectation.etudiant[0].value
      const resP = APID.post("etudiant/get_by_id", {
        id: etudId,
      }).then(resP => {
        setSelectCycle(resP.data.etudiant.cycle)
        setSelectDiplome(resP.data.etudiant.diplome)
        setSelectNiv(resP.data.etudiant.niveau)
        setSelectSpec(resP.data.etudiant.specialite)
        setSelectGroupe(resP.data.etudiant.group)
        setLoading(true)
      })
    })
  }, [])

  const getDiplome = async event => {
    setSelectCycle(event)
    var cycle_id = event.value
    const res = await APID.post("diplome/get_by_cycle", { id: cycle_id }).then(
      res => {
        setArrayDiplome(res.data.Diplomes_By_Cycle)
      }
    )
  }

  const getSpec = async event => {
    setSelectDiplome(event)
    var diplome_id = event.value
    const resD = await APID.post("specialite/get_by_diplome", {
      id: diplome_id,
    }).then(resD => {
      setSpecArray(resD.data.Specialites_By_Diplome)
    })
  }

  const getNiveau = async event => {
    setSelectSpec(event)
    var spec_id = event.value
    const resD = await APID.post("niveau/get_by_specialite", {
      id: spec_id,
    }).then(resD => {
      setArrayNiv(resD.data.Niveaux_by_specialite)
    })
  }

  const getGroupe = async event => {
    setSelectNiv(event)
    const resD = await APID.post("group/get_by_niveau", {
      specialite_id: selectSpec.value,
      cycle_id: selectCycle.value,
      niveau_id: event.value,
    }).then(resD => {
      setArrayGroupe(resD.data.Groups)
    })
  }

  const getEtud = async event => {
    setSelectGroupe(event)
    var groupe_id = event.value
    const resD = await APID.post("etudiant/get_by_group", {
      id: groupe_id,
    }).then(resD => {
      setArrayEtudiant(resD.data.etudiants)
    })
  }

  const changeEse = event => {
    setSelectEse(event)
    const res = API.post("stage/select_type_stage", {
      lang: lng,
      entreprise_id: event.value,
    }).then(res => {
      setArraySujet(res.data.Stage)
    })
  }

  const toggle = () => {
    setModal(!modal)
  }

  const toggleDelete = async () => {
    const res = await API.post("affectation/delete", {
      id_etudiant: idEtud,
      id_encadrant: idEncad,
    }).then(res => {
      setModal(!modal)
      props.history.push("/AffectStage")
    })
  }

  const save = async () => {
    const res = await API.post("affectation/edit", {
      etudiant_id: selectEtud,
      encadrant_id: selectEncad,
      stage_id: selectSujet.value,
      id_etudiant: idEtud,
      id_encadrant: idEncad,
      etat_stage: selectEtatStage.value,
    })
      .then(res => {
        props.history.push("/AffectStage")
      })
      .catch(() => {
        toast.error("Probléme lors de l'insertion", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                    {props.t("Modification affectation stage")}
                  </CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Cycle")}
                          </Label>
                          <Select
                            options={arrayCycle}
                            isSearchable={true}
                            onChange={e => getDiplome(e)}
                            value={selectCycle}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Diplome")}
                          </Label>
                          <Select
                            options={arrayDiplome}
                            isSearchable={true}
                            value={selectDiplome}
                            onChange={e => getSpec(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Spécialité")}
                          </Label>
                          <Select
                            options={specArray}
                            isSearchable={true}
                            value={selectSpec}
                            onChange={e => getNiveau(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Niveau")}
                          </Label>
                          <Select
                            options={arrayNiv}
                            isSearchable={true}
                            value={selectNiv}
                            onChange={e => getGroupe(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Groupe")}
                          </Label>
                          <Select
                            options={arrayGroupe}
                            isSearchable={true}
                            value={selectGroupe}
                            onChange={e => getEtud(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Etudiant")}
                          </Label>
                          <Select
                            isMulti={true}
                            options={arrayEtudiant}
                            isSearchable={true}
                            onChange={setSelectEtud}
                            value={selectEtud}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Type de stage")}
                          </Label>
                          <Select
                            options={arrayEtatStage}
                            isSearchable={true}
                            onChange={setSelectEtatStage}
                            value={selectEtatStage}
                          />
                        </div>
                      </Col>
                      {selectEtatStage.value != 2 ? (
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Encadrant")}
                            </Label>
                            <Select
                              isMulti={true}
                              options={arrayEncadrant}
                              isSearchable={true}
                              onChange={setSelectEncad}
                              value={selectEncad}
                            />
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Entreprise")}
                          </Label>
                          <Select
                            options={arrayEse}
                            isSearchable={true}
                            onChange={changeEse}
                            value={selectEse}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Sujet")}
                          </Label>
                          <Select
                            options={arraySujet}
                            isSearchable={true}
                            onChange={setSelectSujet}
                            value={selectSujet}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    {" "}
                    <div lg="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-danger  mb-2 me-2"
                          onClick={toggle}
                        >
                          {props.t("Supprimer")}
                        </Button>
                      </div>
                    </div>
                    <div lg="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/AffectStage")}
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </div>
                    <div lg="4">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={save}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
            {/* */}
            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <div
                className="modal-header"
                style={{
                  width: "100% !important",
                  justifyContent: "center !important",
                  margin: "0 auto",
                  fontSize: "17px",
                }}
                toggle={toggle}
              >
                {props.t("Suppression affectation")}
              </div>
              <ModalBody>
                <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    <p>
                      {props.t(
                        "Êtes-Vous sûr de vouloir supprimer cette affectation ?"
                      )}
                    </p>
                  </div>
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                    }}
                  >
                    <div>
                      <Button
                        color="info"
                        className="btn-rounded "
                        onClick={toggleDelete}
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                        ></i>
                        {props.t("oui")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={toggle}
                        color="danger"
                        className="btn-rounded "
                      >
                        <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                        ></i>
                        {props.t("non")}
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}

export default withTranslation()(EditAffectStage)
EditAffectStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
