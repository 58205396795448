import React, { useEffect, useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import APID from "../../apiD"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
  ModalFooter,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import axios from "axios"
import { MetaTags } from "react-meta-tags"

const DataTableAnnuaireEns = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState("")
  // Data Table
  const [enseignant, setEnseignant] = useState([])
  //
  useEffect(async () => {
    const res = await APID.post("enseignant/list").then(res => {
      setEnseignant(res.data.enseignants)
      setLoading(true)
    })
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: enseignant.length,
    page: 1,
    nextPageText: props.t("next"),
    prePageText: props.t("back"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: enseignant.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "code",
      text: props.t("Numéro"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Name",
      text: props.t("Nom"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "grade",
      text: props.t("Grade"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "email",
      text: props.t("E-mail"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "mobile",
      text: props.t("Télephone"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Annuaire enseignants")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={enseignant}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {" "}
                  {props.t("load_page")}
                </h4>
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableAnnuaireEns))
DataTableAnnuaireEns.propTypes = {
  enseignant: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
