import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import APIS from "../../apiS"
import APID from "../../apiD"
import { withTranslation } from "react-i18next"
import validator from "validator"

import { AvForm, AvField } from "availity-reactstrap-validation"

const AddAffectStage = props => {
  // lang
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [arrayCycle, setArrayCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [arrayDiplome, setArrayDiplome] = useState([])
  const [selectDiplome, setSelectDiplome] = useState("")
  const [arrayNiv, setArrayNiv] = useState([])
  const [selectNiv, setSelectNiv] = useState("")
  const [specArray, setSpecArray] = useState([])
  const [selectSpec, setSelectSpec] = useState("")
  const [arrayGroupe, setArrayGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [arrayEtudiant, setArrayEtudiant] = useState([])
  const [selectEtud, setSelectEtud] = useState([])
  const [arrayEncadrant, setArrayEncadrant] = useState([])
  const [selectEncad, setSelectEncad] = useState([])
  const [arrayEse, setArrayEse] = useState([])
  const [selectEse, setSelectEse] = useState("")
  const [arraySujet, setArraySujet] = useState([])
  const [selectSujet, setSelectSujet] = useState("")
  const [arrayEtatStage, setEtatStage] = useState([
    { label: "Obligatoire", value: 1 },
    { label: "Volontaire", value: 2 },
  ])
  const [selectEtatStage, setSelectEtatStage] = useState("")
  // new entreprise
  const [newEse, setNewEse] = useState("")
  const [gerant, setGerant] = useState("")
  const [mobile, setMobile] = useState("")
  const [tel, setTel] = useState("")
  const [matricule, setMatricule] = useState("")
  const [adresse, setAdresse] = useState("")
  const [email, setEmail] = useState("")
  const [entrepriseId, setEntrepriseId] = useState("")
  // new sujet
  const [newSujet, setNewSujet] = useState("")
  const [periodeStage, setPeriodeStage] = useState("")
  const [arraySpec, setArraySpec] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState([])
  const [arrayNiveau, setArrayNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState([])
  const [description, setDescription] = useState("")
  const [arrayType, setArrayType] = useState([])
  const [selectType, setSelectType] = useState("")
  // modal
  const [modalOpenClose, setModalOpenClose] = useState(false)
  const [modalEse, setModalEse] = useState(false)
  const [modalSujet, setModalSujet] = useState(false)
  //
  useEffect(async () => {
    // encadrant
    const resS = await API.get("encadreur/list_encadreur_select").then(resS => {
      setArrayEncadrant(resS.data.encadreurs)
    })
    // entreprise
    const resA = await API.post("entreprise/select", {
      lang: lng,
    }).then(resA => {
      setArrayEse(resA.data.Entreprise)
    })
    // type
    const resM = await API.get("type_stage/select", {
      lang: lng,
    }).then(resM => {
      setArrayType(resM.data.Type_stage)
    })
    // cycle
    const resN = await APID.get("cycle/select").then(resN => {
      setArrayCycle(resN.data.Cycle)
    })
    // specialite
    const resL = await APID.get("specialite/select").then(resL => {
      setArraySpec(resL.data.Specialites)
    })
  }, [])

  const getDiplome = async event => {
    setSelectCycle(event)
    var cycle_id = event.value
    const res = await APID.post("diplome/get_by_cycle", { id: cycle_id }).then(
      res => {
        setArrayDiplome(res.data.Diplomes_By_Cycle)
      }
    )
  }

  const getSpec = async event => {
    setSelectDiplome(event)
    var diplome_id = event.value
    const resD = await APID.post("specialite/get_by_diplome", {
      id: diplome_id,
    }).then(resD => {
      setSpecArray(resD.data.Specialites_By_Diplome)
    })
  }

  const getNiveau = async event => {
    setSelectSpec(event)
    var spec_id = event.value
    const resD = await APID.post("niveau/get_by_specialite", {
      id: spec_id,
    }).then(resD => {
      setArrayNiv(resD.data.Niveaux_by_specialite)
    })
  }

  const getGroupe = async event => {
    setSelectNiv(event)
    const resD = await APID.post("group/get_by_niveau", {
      specialite_id: selectSpec.value,
      cycle_id: selectCycle.value,
      niveau_id: event.value,
    }).then(resD => {
      setArrayGroupe(resD.data.Groups)
    })
  }

  const getEtud = async event => {
    setSelectGroupe(event)
    var groupe_id = event.value
    const resD = await APID.post("etudiant/get_by_group", {
      id: groupe_id,
    }).then(resD => {
      setArrayEtudiant(resD.data.etudiants)
    })
  }

  const changeEse = event => {
    setSelectEse(event)
    const res = API.post("stage/select_type_stage", {
      lang: lng,
      entreprise_id: event.value,
    }).then(res => {
      setArraySujet(res.data.Stage)
    })
  }

  const toggleEse = () => {
    setModalEse(!modalEse)
  }

  const toggleSujet = () => {
    setModalSujet(!modalSujet)
  }

  const saveEse = async () => {
    if (
      newEse == "" ||
      gerant == "" ||
      matricule == "" ||
      email == "" ||
      adresse == ""
    ) {
      toast.error("Vérifier les champs vides !", {
        containerId: "A",
      })
    } else if (isNaN(tel) != false) {
      toast.error("Numéro téléphone invalid!", {
        containerId: "A",
      })
    } else if (isNaN(mobile) != false) {
      toast.error("Numéro téléphone fixe invalid!", {
        containerId: "A",
      })
    } else if (validator.isEmail(email) == false) {
      toast.error("Email invalid!", {
        containerId: "A",
      })
    } else {
      const res = await API.post("entreprise/add", {
        nom_entreprise: newEse,
        nom_gerant: gerant,
        matricule: matricule,
        email: email,
        adresse: adresse,
        mobile: mobile,
        telephone: tel,
      })
      if (res.data.status === 200) {
        let entrepriseId = res.data.Entreprise.value
        let entreprise = res.data.Entreprise
        //
        var company = {
          id: entrepriseId,
          nom: newEse,
          email: email,
          active: 0,
          is_compte: -1,
          id_compte: -1,
          active_compte: -1,
          gerant: gerant,
        }
        // create user entreprise
        const resC = await APIS.post("user/create", {
          entreprises: company,
        })
        // notify email
        var val = [
          {
            nom: res.data.Entreprise.label,
            email: res.data.Entreprise.email,
          },
        ]
        const resN = API.post("entreprise/notify_welcome", {
          entreprises: val,
        })
        // activer compte
        const resB = await API.post("entreprise/activer", {
          id: entrepriseId,
          active: 1,
        })
        //
        setEntrepriseId(entrepriseId)
        setSelectEse(entreprise)
        setModalEse(!modalEse)
        const resA = API.post("entreprise/select", {
          lang: lng,
        }).then(resA => {
          setArrayEse(resA.data.Entreprise)
          setModalSujet(!modalSujet)
        })
      }
    }
  }

  const changeSpec = e => {
    setSelectSpecialite(e)
    // niveau
    if (e.length > 0) {
      const res = APID.post("niveau/get_by_specialites", {
        specialites: e,
      }).then(res => {
        setArrayNiveau(res.data.Niveaux_By_Specialite)
      })
    } else {
      setArrayNiveau([])
    }
  }

  const saveSujet = async () => {
    const res = await API.post("stage/add", {
      etat: 1,
      lang: lng,
      entreprise_id: entrepriseId,
      periode_stage: periodeStage,
      description: description,
      sujet: newSujet,
      type_stage: selectType,
      specialite: selectSpecialite,
      niveau: selectNiveau,
    })
      .then(res => {
        let sujet = res.data.Sujet
        setSelectSujet(sujet)
        setModalSujet(!modalSujet)
        const resE = API.post("stage/select_type_stage", {
          lang: lng,
          entreprise_id: entrepriseId,
        }).then(resE => {
          setArraySujet(resE.data.Sujet)
        })
      })
      .catch(() => {
        toast.error("⛔", {
          containerId: "A",
        })
      })
  }

  const openCloseModal = async () => {
    setModalOpenClose(!modalOpenClose)
  }
  const openCloseModalAffectation = async () => {
    if (selectCycle == "") {
      toast.error("Séléctionner un cycle", {
        containerId: "A",
      })
    } else if (selectDiplome == "") {
      toast.error("Séléctionner un diplôme", {
        containerId: "A",
      })
    } else if (selectSpec == "") {
      toast.error("Séléctionner une spécialité", {
        containerId: "A",
      })
    } else if (selectNiv == "") {
      toast.error("Séléctionner un niveau", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      toast.error("Séléctionner un groupe", {
        containerId: "A",
      })
    } else if (selectEtud == "") {
      toast.error("Séléctionner un étudiant", {
        containerId: "A",
      })
    } else if (selectEtatStage == "") {
      toast.error("Séléctionner un type de stage", {
        containerId: "A",
      })
    } else if (selectEncad == "" && selectEtatStage.value == 1) {
      toast.error("Séléctionner un encadrant", {
        containerId: "A",
      })
    } else if (selectEse == "") {
      toast.error("Séléctionner une entreprise", {
        containerId: "A",
      })
    } else if (selectSujet == "") {
      toast.error("Séléctionner un sujet", {
        containerId: "A",
      })
    } else {
      setModalOpenClose(!modalOpenClose)
    }
  }

  const save = async () => {
    const res = await API.post("affectation/add", {
      annee: "2022",
      etudiant_id: selectEtud,
      encadrant_id: selectEncad,
      stage_id: selectSujet.value,
      etat_stage: selectEtatStage.value,
    })
      .then(res => {
        props.setSection(1)
      })
      .catch(() => {
        toast.error("Probléme lors de l'insertion", {
          containerId: "A",
        })
      })
  }
  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Cycle")}
                </Label>
                <Select
                  options={arrayCycle}
                  isSearchable={true}
                  onChange={e => getDiplome(e)}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Diplôme")}
                </Label>
                <Select
                  options={arrayDiplome}
                  isSearchable={true}
                  value={selectDiplome}
                  onChange={e => getSpec(e)}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Spécialité")}
                </Label>
                <Select
                  options={specArray}
                  isSearchable={true}
                  value={selectSpec}
                  onChange={e => getNiveau(e)}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Niveau")}
                </Label>
                <Select
                  options={arrayNiv}
                  isSearchable={true}
                  value={selectNiv}
                  onChange={e => getGroupe(e)}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Groupe")}
                </Label>
                <Select
                  options={arrayGroupe}
                  isSearchable={true}
                  value={selectGroupe}
                  onChange={e => getEtud(e)}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Etudiant")}
                </Label>
                <Select
                  isMulti={true}
                  options={arrayEtudiant}
                  isSearchable={true}
                  onChange={setSelectEtud}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Type de stage")}
                </Label>
                <Select
                  options={arrayEtatStage}
                  isSearchable={true}
                  onChange={setSelectEtatStage}
                  placeholder={props.t("Select")}
                />
              </div>
            </Col>
            {selectEtatStage.value != 2 ? (
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Encadrant")}
                  </Label>
                  <Select
                    isMulti={true}
                    options={arrayEncadrant}
                    isSearchable={true}
                    onChange={setSelectEncad}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Entreprise")}
                </Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "95%" }}>
                    <Select
                      options={arrayEse}
                      isSearchable={true}
                      onChange={changeEse}
                      placeholder={props.t("Select")}
                    />
                  </div>
                  <div style={{ width: "3%" }}>
                    <button
                      type="button"
                      className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      <span
                        onClick={toggleEse}
                        className="avatar-title bg-transparent text-reset"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-plus"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Sujet")}
                </Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "95%" }}>
                    <Select
                      options={arraySujet}
                      isSearchable={true}
                      onChange={setSelectSujet}
                      placeholder={props.t("Select")}
                    />
                  </div>
                  <div style={{ width: "3%" }}>
                    <button
                      type="button"
                      className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      <span
                        onClick={toggleSujet}
                        className="avatar-title bg-transparent text-reset"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-plus"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            direction: lng == "ar" ? "initial" : "initial",
          }}
        >
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button
                type="button"
                className="btn btn-primary "
                onClick={openCloseModalAffectation}
              >
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      {/* */}
      <Modal
        style={{
          maxWidth: "50%",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modalEse}
        toggle={toggleEse}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            direction: lng == "ar" ? "rtl" : "initial",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleEse}
        >
          {props.t("Ajout nouvelle entreprise")}
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    {props.t("Entreprise")}
                  </Label>
                  <AvField
                    name="Entreprise"
                    placeholder="Entrer nom entreprise"
                    type="text"
                    errorMessage="* Entreprise obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setNewEse(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    {props.t("Gerant")}
                  </Label>
                  <AvField
                    name="Gérant"
                    placeholder="Entrer gérant"
                    type="text"
                    errorMessage="* Gérant obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setGerant(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-Téléphone-input">
                    {props.t("Téléphone")}
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder={props.t("Téléphone")}
                    onChange={e => setTel(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-fixe-input">
                    {props.t("Téléphone fixe")}
                  </Label>
                  <Input
                    lg="3"
                    type="text"
                    className="form-control"
                    placeholder={props.t("Téléphone fixe")}
                    onChange={e => setMobile(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    {props.t("Matricule")}
                  </Label>
                  <AvField
                    name="Matricule "
                    placeholder="Entrer matricule"
                    type="text"
                    errorMessage="* Matricule fixe obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setMatricule(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    {props.t("Adresse")}
                  </Label>
                  <AvField
                    name="Adresse "
                    placeholder="Entrer adresse"
                    type="text"
                    errorMessage="* Adresse obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setAdresse(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">
                    {props.t("Email")}
                  </Label>
                  <AvField
                    name="E-mail "
                    placeholder="Entrer e-mail"
                    type="text"
                    errorMessage="*E-mail obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                direction: lng == "ar" ? "initial" : "initial",
              }}
            >
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={toggleEse}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={saveEse}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      {/* */}
      <Modal
        style={{
          maxWidth: "50%",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modalSujet}
        toggle={toggleSujet}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            direction: lng == "ar" ? "rtl" : "initial",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleSujet}
        >
          {props.t("Ajout nouveau stage")}
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Sujet")}{" "}
                  </Label>
                  <AvField
                    name="Sujet"
                    placeholder="Entrer sujet"
                    type="text"
                    errorMessage="* Sujet obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setNewSujet(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Période de stage")}{" "}
                  </Label>
                  <AvField
                    name="Période de stage"
                    placeholder="Entrer période de stage"
                    type="text"
                    errorMessage="* Période de stage obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setPeriodeStage(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Type")}
                  </Label>
                  <Select
                    isMulti={true}
                    options={arrayType}
                    isSearchable={true}
                    onChange={e => setSelectType(e)}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Specialite")}
                  </Label>
                  <Select
                    isMulti={true}
                    options={arraySpec}
                    isSearchable={true}
                    onChange={e => changeSpec(e)}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Niveau")}
                  </Label>
                  <Select
                    isMulti={true}
                    options={arrayNiveau}
                    isSearchable={true}
                    onChange={setSelectNiveau}
                    placeholder={props.t("Select")}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Description")}
                  </Label>
                  <AvField
                    name="Description"
                    placeholder="Entrer description"
                    type="text"
                    errorMessage="*Descriptione obligatoire"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={e => setDescription(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </AvForm>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              direction: lng == "ar" ? "initial" : "initial",
            }}
          >
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={toggleSujet}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={saveSujet}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      {/* */}
      <Modal
        style={{
          maxWidth: "50%",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modalOpenClose}
        toggle={openCloseModal}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            direction: lng == "ar" ? "rtl" : "initial",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={openCloseModal}
        >
          {props.t("Affectation stage")}
        </div>
        <ModalBody style={{ direction: lng == "ar" ? "rtl" : "initial" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ color: "indianred" }}>
              {props.t("Êtes-Vous sûr de vouloir enregister ces données ")}{" "}
              <i className="fas fa-exclamation"></i>
            </p>
          </div>
          <Form>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Etudiant")}
                  </Label>
                  <Select isMulti={true} value={selectEtud} isDisabled={true} />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Sujet")}
                  </Label>
                  <Select value={selectSujet} isDisabled={true} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Entreprise")}
                  </Label>
                  <Select value={selectEse} isDisabled={true} />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Type de stage")}
                  </Label>
                  <Select value={selectEtatStage} isDisabled={true} />
                </div>
              </Col>
            </Row>
            <Row>
              {selectEtatStage.value != 2 ? (
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      {props.t("Encadrant")}
                    </Label>
                    <Select
                      isMulti={true}
                      value={selectEncad}
                      isDisablSed={true}
                    />
                  </div>
                </Col>
              ) : null}
            </Row>
          </Form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              direction: lng == "ar" ? "initial" : "initial",
            }}
          >
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={openCloseModal}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={save}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddAffectStage)
AddAffectStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
