import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import Select from "react-select"
import APID from "../../apiD"
import { withTranslation } from "react-i18next"
import { AvForm, AvField } from "availity-reactstrap-validation"
import validator from "validator"

const AddEncadreur = props => {
  // lang
  var lng = localStorage.getItem("I18N_LANGUAGE")
  const [loading, setLoading] = useState(true)
  const [encadreur, setEncadreur] = useState([])
  const [selectEncadreur, setSelectEncadreur] = useState("")
  const [modal, setModal] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  useEffect(async () => {
    const res = await APID.get("enseignant/select").then(res => {
      setEncadreur(res.data.select_enseignant)
    })
  }, [])
  const toggle = () => {
    setModal(!modal)
  }
  const saveEnc = async () => {
    if (selectEncadreur != "") {
      setLoading(false)
      const res = await API.post("encadreur/add_encadreur", {
        firstName: firstName,
        lastName: lastName,
        telephone: phone,
        email: email,
        encadreur: selectEncadreur,
      }).then(res => {
        setModal(false)
        props.setSection(1)
      })
    } else {
      toast.error("Séléctionner au moins un encadreur", {
        containerId: "A",
      })
    }
  }
  const saveEncExt = async () => {
    if (firstName == "" || lastName == "" || phone == "" || email == "") {
      toast.error("Vérifier les champs vides!", {
        containerId: "A",
      })
    } else if (isNaN(phone) == true) {
      toast.error("Numéro téléphone invalid!", {
        containerId: "A",
      })
    } else if (validator.isEmail(email) == false) {
      toast.error("Email invalid!", {
        containerId: "A",
      })
    } else {
      setLoading(false)
      const res = await API.post("encadreur/add_encadreur", {
        firstName: firstName,
        lastName: lastName,
        telephone: phone,
        email: email,
        encadreur: selectEncadreur,
      }).then(res => {
        setModal(false)
        props.setSection(1)
      })
    }
  }
  return (
    <React.Fragment>
      {loading ? (
        <div>
          <Row>
            <Form>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      {props.t("Encadreur")}
                    </Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ width: "95%" }}>
                        <Select
                          options={encadreur}
                          isSearchable={true}
                          isClearable={true}
                          onChange={e => setSelectEncadreur(e)}
                          isMulti={true}
                          placeholder={props.t("Select")}
                        />
                      </div>
                      <div style={{ width: "3%" }}>
                        <button
                          type="button"
                          className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                          style={{ backgroundColor: "cornflowerblue" }}
                        >
                          <span
                            onClick={toggle}
                            className="avatar-title bg-transparent text-reset"
                          >
                            <i
                              style={{ color: "white" }}
                              className="fas fa-plus"
                            ></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                direction: lng == "ar" ? "initial" : "initial",
              }}
            >
              {" "}
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={saveEnc}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </Col>
            </div>
          </Row>
          <Modal
            style={{
              maxWidth: "50%",
              width: "100%",
              position: "absolute",
              direction: lng == "ar" ? "initial" : "initial",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            isOpen={modal}
            toggle={toggle}
            centered={true}
          >
            <div
              className="modal-header"
              style={{
                width: "100% !important",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
              }}
              toggle={toggle}
            >
              {props.t("Ajouter un nouveau encadreur")}
            </div>
            <ModalBody>
              <AvForm>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        {props.t("Nom")}
                      </Label>
                      <AvField
                        name="Nom"
                        placeholder="Entrer nom"
                        type="text"
                        errorMessage="* Nom obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setFirstName(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        {props.t("Prénom")}
                      </Label>
                      <AvField
                        name="Prénom"
                        placeholder="Entrer prénom"
                        type="text"
                        errorMessage="* Prénom obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setLastName(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        {props.t("Téléphone")}
                      </Label>
                      <AvField
                        name="Téléphone"
                        placeholder="Entrer téléphone"
                        type="text"
                        errorMessage="* Téléphone obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setPhone(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        {props.t("E-mail")}
                      </Label>
                      <AvField
                        name="E-mail"
                        placeholder="Entrer e-mail"
                        type="text"
                        errorMessage="* E-mail obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    direction: lng == "ar" ? "initial" : "initial",
                  }}
                >
                  {" "}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={toggle}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                    >
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={saveEncExt}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </Col>
                </div>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}
export default withTranslation()(AddEncadreur)
AddEncadreur.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
