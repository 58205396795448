import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import { set } from "lodash"
const DetailEntreprise = props => {
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [entreprise, setEntreprise] = useState("")
  const [adresse, setAdresse] = useState("")
  const [gérant, setGerant] = useState()
  const [telephone, setTelephone] = useState("")
  const [mobile, setMobile] = useState()
  const [matricule, setMatricule] = useState()
  const [email, setEmail] = useState("")
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    //
    setId(id)
    //
    const res = await API.post("entreprise/get_by_id", { id }).then(res => {
      setEntreprise(res.data.Entreprise.entreprise)
      setAdresse(res.data.Entreprise.adresse)
      setGerant(res.data.Entreprise.gerant)
      setTelephone(res.data.Entreprise.telephone)
      setMobile(res.data.Entreprise.mobile)
      setMatricule(res.data.Entreprise.matricule)
      setEmail(res.data.Entreprise.email)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                    {props.t("Detail entreprise")}
                  </CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Entreprise")}
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            defaultValue={entreprise}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Adresse")}
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            defaultValue={adresse}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Gérant")}
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={gérant}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Téléphone")}
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            value={telephone}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Téléphone fixe ")}
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            value={mobile}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Matricule")}
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            value={matricule}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Email")}
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={email}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Entreprise")}
                        >
                          {props.t("Retour")}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(DetailEntreprise))
DetailEntreprise.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
