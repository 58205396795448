import React, { useEffect, useState } from "react"
import PropTypes, { array } from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Spinner,
  Button,
  Input,
  Progress,
  ModalHeader,
  Modal,
  ModalBody,
  Form,
  Label,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import Select from "react-select"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Switch from "react-switch"
import { withTranslation } from "react-i18next"
//
const Entreprise = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [file, setFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [arraySelect, setArraySelect] = useState([])
  const [arraySelectAll, setArraySelectAll] = useState([])
  const [actions, setActions] = useState([
    { value: 1, label: "Générer Compte" },
    { value: 2, label: "Suppression" },
  ])
  const [selectAction, setSelectAction] = useState("")
  const [entreprise, setEntreprise] = useState([])
  const [modal1, setModal1] = useState(false)
  const [entrepriseId, setentrepriseId] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: entreprise.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: entreprise.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "nom",
      text: props.t("Entreprise"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "gerant",
      text: props.t("Gérant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "email",
      text: props.t("E-mail"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Exploration",
      isDummyField: true,
      text: props.t("Exploration"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/DetailEntreprise?id=" + row.id}>
            <i
              style={{ color: "darkcyan", cursor: "pointer" }}
              className="fas fa-eye"
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "Compte",
      isDummyField: true,
      text: props.t("Compte"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.is_compte == -1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "blueviolet", cursor: "pointer" }}
              className="fas fa-user-plus"
              onClick={() => addAccount(row)}
            ></i>
          </div>
        ) : row.is_compte == 1 && row.active_compte == 0 ? (
          <div
            onClick={() => toggle1(row)}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <i
              style={{ color: "red", cursor: "pointer" }}
              className="fas fa-user"
            ></i>
          </div>
        ) : (
          <div
            onClick={() => toggle1(row)}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <i
              style={{ color: "#34c38f", cursor: "pointer" }}
              className="fas fa-user"
            ></i>
          </div>
        ),
    },
    {
      dataField: "active",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (colIndex, row) => (
        <Switch
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          className="me-1 mb-sm-8 mb-2"
          onColor="#34c38f"
          onChange={() => activeEntreprise(row)}
          checked={row.active}
        />
      ),
    },
  ]

  const Offsymbol = () => {
    return (
      <div
        title="Activé"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        A
      </div>
    )
  }

  const OnSymbol = props => {
    return (
      <div
        title="Désactivé"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        D
      </div>
    )
  }

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await APIS.get("user/list_id_entreprise").then(resE => {
      const resD = API.post("entreprise/list_entreprise", {
        comptes: resE.data.comptes,
      }).then(resD => {
        setEntreprise(resD.data.Entreprise)
        setLoading(true)
      })
    })
  }, [])

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        var array = arraySelect
        array.push(row)
        setArraySelect(array)
        let tab = [...arraySelectAll]
        tab.push(row)
        setArraySelectAll(tab)
      } else {
        var array = arraySelect
        array.splice(rowIndex, 1)
        setArraySelect(array)
        if (arraySelectAll.length != 0) {
          let tab = [...arraySelectAll]
          let etudId = row.id
          for (let index = 0; index < tab.length; index++) {
            let idEtud = tab[index].id
            if (idEtud == etudId) {
              tab.splice(index, 1)
              setArraySelectAll(tab)
              break
            }
          }
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        var array = arraySelectAll
        for (let i = 0; i < entreprise.length; i++) {
          array.push(entreprise[i])
        }
        setArraySelectAll(array)
      } else {
        setArraySelectAll([])
      }
    },
  }

  const action = async () => {
    setLoading(true)
    if (selectAction.value == 1) {
      if (arraySelectAll.length != 0) {
        var entreprises = arraySelectAll
      } else {
        var entreprises = arraySelect
      }
      const res = await APIS.post("user/generate_accounts", {
        entreprises: entreprises,
      }).then(res => {
        toast.success("Les comptes sont générés avec succès ", {
          containerId: "A",
        })
        const resN = API.post("entreprise/notify_welcome", {
          entreprises: entreprises,
        })
        const resE = APIS.get("user/list_id_entreprise").then(resE => {
          const resD = API.post("entreprise/list_entreprise", {
            comptes: resE.data.comptes,
          }).then(resD => {
            setEntreprise(resD.data.Entreprise)
            setLoading(true)
          })
        })
      })
    } else if (selectAction.value == 2) {
      if (arraySelectAll.length != 0) {
        var entreprises = arraySelectAll
      } else {
        var entreprises = arraySelect
      }
      const resD = await API.post("entreprise/deletes", {
        entreprises: entreprises,
      }).then(resD => {
        const resDA = APIS.post("user/delete_accounts", {
          entreprises: resD.data.entreprises,
        }).then(resDA => {
          toast.success("Les comptes supprimés avec succés ", {
            containerId: "A",
          })
          const resE = APIS.get("user/list_id_entreprise").then(resE => {
            const resD = API.post("entreprise/list_entreprise", {
              comptes: resE.data.comptes,
            }).then(resD => {
              setEntreprise(resD.data.Entreprise)
              setLoading(true)
            })
          })
        })
      })
    }
  }

  const activeEntreprise = async row => {
    var active = row.active
    var entrepriseId = row.id
    if (active == 0) {
      var active = 1
      const res = await API.post("entreprise/activer", {
        id: entrepriseId,
        active: active,
      }).then(res => {
        toast.success(" 🔓✔️ Entreprise activé avec succés", {
          containerId: "A",
        })
        const resE = APIS.get("user/list_id_entreprise").then(resE => {
          const resD = API.post("entreprise/list_entreprise", {
            comptes: resE.data.comptes,
          }).then(resD => {
            setEntreprise(resD.data.Entreprise)
            setLoading(true)
          })
        })
      })
    } else if (active == 1) {
      var active = 0
      const res = await API.post("entreprise/activer", {
        id: entrepriseId,
        active: active,
      }).then(res => {
        toast.success(" 🔒❌ Entreprise désactivé avec succés ", {
          containerId: "A",
        })
        const resE = APIS.get("user/list_id_entreprise").then(resE => {
          const resD = API.post("entreprise/list_entreprise", {
            comptes: resE.data.comptes,
          }).then(resD => {
            setEntreprise(resD.data.Entreprise)
            setLoading(true)
          })
        })
      })
    }
  }

  const addAccount = async row => {
    var entreprise = row
    const res = await APIS.post("user/create", {
      entreprises: entreprise,
    }).then(res => {
      toast.success("Compte ajouté avec succés ", {
        containerId: "A",
      })
      let arrayEntre = []
      arrayEntre.push(row)
      const resN = API.post("entreprise/notify_welcome", {
        entreprises: arrayEntre,
      })
      const resE = APIS.get("user/list_id_entreprise").then(resE => {
        const resD = API.post("entreprise/list_entreprise", {
          comptes: resE.data.comptes,
        }).then(resD => {
          setEntreprise(resD.data.Entreprise)
          setLoading(true)
        })
      })
    })
  }

  const toggle = () => {
    setModal(!modal)
    setUploadPercent(0)
  }

  const toggle2 = async row => {
    setModal1(!modal1)
  }

  const toggle1 = async row => {
    setModal1(!modal1)
    setName(row.nom)
    setEmail(row.email)
    setentrepriseId(row)
  }

  const edit = async entrepriseId => {
    var id = entrepriseId.id
    const res = await APIS.post("user/update", {
      id: id,
      email: email,
      password: password,
    }).then(res => {
      setModal1(false)
    })
  }

  const activate = async entrepriseId => {
    var id = entrepriseId.id
    var active = entrepriseId.active_compte
    if (active == 0) {
      var active = 1
      const res = await APIS.post("user/activate", {
        id: id,
        active: active,
      }).then(res => {
        setModal1(false)
        toast.success(" 🔓✔️ Compte activé avec succés", {
          containerId: "A",
        })
        const resE = APIS.get("user/list_id_entreprise").then(resE => {
          const resD = API.post("entreprise/list_entreprise", {
            comptes: resE.data.comptes,
          }).then(resD => {
            setEntreprise(resD.data.Entreprise)
            setLoading(true)
          })
        })
      })
    } else if (active == 1) {
      var active = 0
      const res = await APIS.post("user/activate", {
        id: id,
        active: active,
      }).then(res => {
        setModal1(false)
        toast.success(" 🔒❌ Compte désactivé avec succés", {
          containerId: "A",
        })
        const resE = APIS.get("user/list_id_entreprise").then(resE => {
          const resD = API.post("entreprise/list_entreprise", {
            comptes: resE.data.comptes,
          }).then(resD => {
            setEntreprise(resD.data.Entreprise)
            setLoading(true)
          })
        })
      })
    }
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const uploadFile = async () => {
    let data = new FormData()
    data.append("file", file)
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.floor((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        if (percent < 100) {
          setUploadPercent(percent)
        }
      },
    }
    API.post("entreprise/import", data, options).then(res => {
      setUploadPercent(100)
      const resE = APIS.get("user/list_id_entreprise").then(resE => {
        const resD = API.post("entreprise/list_entreprise", {
          comptes: resE.data.comptes,
        }).then(resD => {
          setEntreprise(resD.data.Entreprise)
          setLoading(true)
        })
      })
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Entreprise")}</title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <Row className="mt-4">
              <Col xs="12">
                <ToolkitProvider
                  keyField="id"
                  data={entreprise}
                  columns={Columns()}
                  search
                  bootstrap4
                >
                  {toolkitProps => (
                    <div>
                      <div>
                        <div className="mb-3 row" style={{ display: "flex" }}>
                          <div className="col-md-6 mb-3 row">
                            <div className="col-md-8 select-action">
                              <Select
                                options={actions}
                                isSearchable={true}
                                onChange={e => setSelectAction(e)}
                                placeholder={props.t("Actions")}
                              />
                            </div>
                            <div className="col-md-4 appliquer-button">
                              <Button
                                type="button"
                                color="primary"
                                className="btn btn-primary"
                                onClick={action}
                              >
                                {props.t("Appliquer")}
                              </Button>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3 row">
                            {/* <div
                              className="col-md-4 import-button"
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                type="button"
                                color="success"
                                className="btn  mb-2 me-2"
                                onClick={toggle}
                              >
                                <i className="fas fa-file-import me-1" />
                                {props.t("Import")}
                              </Button>
                            </div> */}
                            <div className="col-md-8 search-box-etd">
                              <div
                                className="search-box me-2 mb-2 d-inline-block"
                                style={{ width: "100%" }}
                              >
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication={() => <NoDataIndication />}
                        striped={false}
                        bordered={false}
                        selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"table-light"}
                        hover
                        pagination={paginationFactory(pageOptions)}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "20px",
            color: "#556ee6",
          }}
          toggle={toggle}
        >
          {props.t("Importation liste des entreprises")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            {uploadPercent <= 0 ? (
              <Input
                onChange={e => addFile(e)}
                className="form-control mt-4"
                type="file"
                id="formFile"
              />
            ) : null}
            {uploadPercent > 0 && uploadPercent < 100 ? (
              <Progress
                striped
                className="progress-xl"
                color="success"
                value={uploadPercent}
              >
                {uploadPercent}%
              </Progress>
            ) : null}
            {uploadPercent == 100 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                Success
                <i
                  style={{
                    paddingLeft: "5px",
                    color: "darkcyan",
                    paddingTop: "3.5px",
                  }}
                  className="fas fa-check me-1"
                />
              </div>
            ) : null}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="button"
                color="success"
                className="btn mb-2 me-2 mt-4"
                onClick={uploadFile}
              >
                <i className="fas fa-cloud-upload-alt me-1" />
                {props.t("Upload")}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle2} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle2}
        >
          {name}
        </div>
        <ModalBody>
          <Row>
            <Form>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      {props.t("Email")}
                    </Label>
                    <Input
                      lg="3"
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      {props.t("Mot de passe")}
                    </Label>
                    <Input
                      lg="3"
                      type="password"
                      className="form-control"
                      placeholder="Mot de passe"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <div lg="4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  {entrepriseId.active_compte == 0 ? (
                    <Button
                      type="button"
                      color="success"
                      className="btn btn-primary  mb-2 me-2"
                      onClick={() => activate(entrepriseId)}
                    >
                      {props.t("Activer")}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      color="danger"
                      className="btn btn-primary  mb-2 me-2"
                      onClick={() => activate(entrepriseId)}
                    >
                      {props.t("Desactiver")}
                    </Button>
                  )}
                </div>
              </div>
              <di lg="4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={toggle2}
                  >
                    {props.t("Annuler")}
                  </Button>
                </div>
              </di>
              <div lg="4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => edit(entrepriseId)}
                  >
                    {props.t("Confirmer")}
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Entreprise))
Entreprise.propTypes = {
  entreprise: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
