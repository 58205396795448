import React, { useEffect, useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import APIS from "../../apiS"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
  ModalFooter,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import axios from "axios"
import { MetaTags } from "react-meta-tags"

const DataTableServiceStage = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const lang = localStorage.getItem("I18N_LANGUAGE")
  //
  const [sujet, setSujet] = useState()
  const [description, setDescription] = useState()
  const [anneeScol, setAnneeScol] = useState(": 2021/2022")
  const [typeStage, setTypeStage] = useState()
  const [periodeStage, setPeriodeStage] = useState()
  const [specialite, setSpecialite] = useState()
  const [niveau, setNiveau] = useState()
  // Data Table
  const [offreStage, setOffreStage] = useState([])
  useEffect(async () => {
    const res = await API.get("stage/list", {
      lang: lang,
    }).then(res => {
      setOffreStage(res.data.Stage)
      setLoading(true)
    })
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: offreStage.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: offreStage.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "entreprise_id",
      text: props.t("Entreprise"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "sujet",
      text: props.t("Sujet"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "periode_stage",
      text: props.t("Période de stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etat",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={
              row.etat == 0
                ? { color: "#f1b44c", display: "flex" }
                : row.etat == 2
                ? { color: "red", display: "flex" }
                : { color: "green", display: "flex" }
            }
            className={
              row.etat == 0
                ? "fas fa-times"
                : row.etat == 2
                ? "fas fa-ban"
                : "fas fa-check"
            }
          >
            <p style={{ paddingLeft: "5px" }}>
              {row.etat == 0
                ? lng == "ar"
                  ? "غير مفعل"
                  : lng == "en"
                  ? "Inactive"
                  : "Inactif"
                : row.etat == 2
                ? lng == "ar"
                  ? "رفض"
                  : lng == "en"
                  ? "Refuse"
                  : "Refuser"
                : lng == "ar"
                ? "نشيط"
                : lng == "en"
                ? "Active"
                : "Actif"}
            </p>
          </i>
        </div>
      ),
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: props.t("Action"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {row.affect == 0 ? (
            <i
              onClick={() => toggle(row.id)}
              style={{ cursor: "pointer" }}
              className="fas fa-cogs"
            ></i>
          ) : (
            <i
              style={{ cursor: "pointer", color: "rgb(167, 163, 163)" }}
              className="fas fa-cogs"
              title="Etat de stage non modifiable"
            ></i>
          )}
        </div>
      ),
    },

    {
      dataField: "Etat affectation",
      isDummyField: true,
      text: props.t("Etat affectation"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ paddingLeft: "5px" }}>
            {row.affect == 1
              ? lng == "ar"
                ? "معين"
                : lng == "en"
                ? "Affected"
                : "Affecté"
              : row.affect == 0
              ? lng == "ar"
                ? "غير معين"
                : lng == "en"
                ? "Unaffected"
                : "Non affecté"
              : null}
          </p>
        </div>
      ),
    },
  ]

  const toggle = id => {
    setModal(!modal)
    setId(id)
    const res = API.post("stage/getById", {
      id: id,
      lang: lang,
    }).then(res => {
      setSujet(": " + res.data.Stage.sujet)
      setDescription(": " + res.data.Stage.description)
      setPeriodeStage(": " + res.data.Stage.periode_stage)
      // type de stage
      let typeStage = ""
      let arrayTypeStage = res.data.Stage.type_stage
      for (let i = 0; i < arrayTypeStage.length; i++) {
        typeStage += arrayTypeStage[i].label += "-"
      }
      typeStage = typeStage.slice(0, -1)
      setTypeStage(": " + typeStage)
      // specialite
      let spec = ""
      let arraySpec = res.data.Stage.specialite
      for (let i = 0; i < arraySpec.length; i++) {
        spec += arraySpec[i].label += "-"
      }
      spec = spec.slice(0, -1)
      setSpecialite(": " + spec)
      // niveau
      let niv = ""
      let arrayNiv = res.data.Stage.niveau
      for (let i = 0; i < arrayNiv.length; i++) {
        niv += arrayNiv[i].label += "-"
      }
      niv = niv.slice(0, -1)
      setNiveau(": " + niv)
      setLoad(true)
    })
  }

  const closeModal = () => {
    setModal(!modal)
    setLoad(false)
  }

  const edit = etat => {
    const res = API.post("stage/valid", {
      id: id,
      etat: etat,
    }).then(res => {
      const resD = API.get("stage/list", {
        lang: lang,
      }).then(resD => {
        setModal(!modal)
        setOffreStage(resD.data.Stage)
      })
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Offres de stage")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                    keyField="id"
                    data={offreStage}
                    columns={Columns()}
                    search
                    bootstrap4
                  >
                    {toolkitProps => (
                      <div>
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication />}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                  style={{ textAlign: "center", marginTop: "2%" }}
                  className="ms-6"
                >
                  {" "}
                  {props.t("load_page")}
                </h4>
              </div>
            )}
          </div>
          <Modal isOpen={modal} toggle={closeModal} centered={true}>
            <div
              className="modal-header"
              style={{
                width: "100% !important",
                direction: lng == "ar" ? "rtl" : "initial",
                justifyContent: "center !important",
                margin: "0 auto",
                fontSize: "17px",
              }}
              toggle={closeModal}
            >
              {props.t("Mise à jour état offre de stage")}
            </div>
            {load == true ? (
              <Fragment>
                <ModalBody>
                  <div
                    style={{
                      direction: lng == "ar" ? "rtl" : "initial",
                    }}
                  >
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>{props.t("Sujet")} </p>{" "}
                      <p style={{ paddingLeft: "5px" }}>{sujet}</p>
                    </span>
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>
                        {props.t("Description")}{" "}
                      </p>
                      <p style={{ paddingLeft: "5px" }}>{description}</p>
                    </span>
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>
                        {props.t("Année universitaire")}{" "}
                      </p>
                      <p style={{ paddingLeft: "5px" }}>{anneeScol}</p>
                    </span>
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>
                        {props.t("Type de stage")}{" "}
                      </p>{" "}
                      <p style={{ paddingLeft: "5px" }}>{typeStage}</p>
                    </span>
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>
                        {props.t("Periode de stage")}{" "}
                      </p>{" "}
                      <p style={{ paddingLeft: "5px" }}>{periodeStage}</p>
                    </span>
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>
                        {props.t("Spécialité")}{" "}
                      </p>{" "}
                      <p style={{ paddingLeft: "5px" }}>{specialite}</p>
                    </span>
                    <span style={{ display: "flex" }}>
                      <p style={{ fontWeight: "bold" }}>{props.t("Niveau")} </p>{" "}
                      <p style={{ paddingLeft: "5px" }}>{niveau}</p>
                    </span>
                  </div>
                </ModalBody>
                <ModalFooter
                  style={{ direction: lng == "ar" ? "rtl" : "initial" }}
                >
                  <div
                    className="hvr-push"
                    style={{
                      display: "flex",
                      direction: lng == "ar" ? "rtl" : "initial",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                      width: "100%",
                    }}
                  >
                    <div>
                      <Button
                        color="info"
                        className="btn-rounded "
                        onClick={() => edit(1)}
                        style={{
                          backgroundColor: "#62b9a9",
                          borderColor: "#62b9a9",
                        }}
                      >
                        <i
                          style={{ color: "white", paddingRight: "5px" }}
                          className="fas fa-check"
                        ></i>
                        {props.t("Activer")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => edit(0)}
                        color="warning"
                        className="btn-rounded "
                      >
                        <i
                          style={{ color: "white", paddingRight: "5px" }}
                          className="fas fa-times"
                        ></i>
                        {props.t("Désactiver")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => edit(2)}
                        color="danger"
                        className="btn-rounded "
                      >
                        <i
                          className="fas fa-ban"
                          style={{ color: "white", paddingRight: "5px" }}
                        ></i>
                        {props.t("Refuser")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={closeModal}
                        color="primary"
                        className="btn-rounded "
                      >
                        <i
                          className="fas fa-external-link-alt"
                          style={{ color: "white", paddingRight: "5px" }}
                        ></i>
                        {props.t("Annuler")}
                      </Button>
                    </div>
                  </div>
                </ModalFooter>
              </Fragment>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4 style={{ textAlign: "center" }} className="ms-6">
                  {props.t("load_page")}
                </h4>
              </div>
            )}
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableServiceStage))
DataTableServiceStage.propTypes = {
  offreStage: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
