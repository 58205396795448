import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
//
const EditNoteSoutenance = props => {
  // lang
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [soutId, setSoutId] = useState([])
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [selectEtudiant, setSelectEtudiant] = useState("")
  const [etudiant, setEtudiant] = useState("")
  const [mention, setMention] = useState([])
  const [selectMention, setSelectMention] = useState("")
  const [note, setNote] = useState("")
  const [modal, setModal] = useState(false)
  //
  useEffect(async () => {
    var lng = localStorage.getItem("I18N_LANGUAGE")
    // get by id
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setSoutId(id)
    const resD = await API.post("note/get_by_id", {
      id: id,
    }).then(resD => {
      setSelectEtudiant(resD.data.Note_soutenance.etudiant)
      setEtudiant(resD.data.Note_soutenance.etudiant.label)
      setSelectMention(resD.data.Note_soutenance.mention)
      setNote(resD.data.Note_soutenance.note)
      setId(resD.data.Note_soutenance.id)
      setLoading(true)
    })
    // mention
    const resS = await API.post("mention/select", {
      lang: lng,
    }).then(resS => {
      setMention(resS.data.Mention)
    })
  }, [])

  const toggle = () => {
    setModal(!modal)
  }
  const toggleDelete = async () => {
    const res = await API.post("note/delete", {
      id: id,
    }).then(res => {
      setModal(!modal)
      props.history.push("NoteSoutenance")
    })
  }

  const edit = async () => {
    if (selectMention != "" && note != "") {
      const res = await API.post("note/update", {
        id: id,
        soutenance_id: soutId,
        etudiant_id: selectEtudiant.value,
        mention_id: selectMention.value,
        note: note,
      })
        .then(res => {
          props.history.push("NoteSoutenance")
        })
        .catch(() => {
          toast.error("Veuillez vérifier tous les champs !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("Veuillez saisir une note et une mention !", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Row>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Etudiant")}
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={etudiant}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Note")}
                          </Label>
                          <AvField
                            name="note"
                            errorMessage="* Note obligatoire"
                            required
                            lg="3"
                            type="text"
                            className="form-control"
                            value={note}
                            onChange={e => setNote(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            {props.t("Mention")}
                          </Label>
                          <Select
                            options={mention}
                            isSearchable={true}
                            onChange={setSelectMention}
                            value={selectMention}
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: lng == "ar" ? "initial" : "initial",
                    }}
                  >
                    {" "}
                    <Col
                      lg="4"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={() => props.history.push("/NoteSoutenance")}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </Col>
                    <Col
                      lg="4"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        type="button"
                        color="danger"
                        className="btn btn-danger  mb-2 me-2"
                        onClick={toggle}
                      >
                        {props.t("Supprimer")}
                      </Button>
                    </Col>
                    <Col
                      lg="4"
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Button
                        type="button"
                        color="primary"
                        className="btn btn-primary  mb-2 me-2"
                        onClick={edit}
                      >
                        {props.t("Confirmer")}
                      </Button>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
      {/* <> */}
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Suppression note soutenance")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.t("Êtes-Vous sûr de vouloir supprimer cette note ?")}
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditNoteSoutenance))
EditNoteSoutenance.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
