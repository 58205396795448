import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages

import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// service stage
import DataTableOffreStage from "pages/OffreStage/DataTableOffreStage"
// affectation stage
import AffectStage from "pages/AffectationStage/AffectStage"
import EditAffectStage from "pages/AffectationStage/EditAffectStage"
// soutenance
import DataTableSoutenance from "pages/Soutenance/DataTableSoutenance"
import DatatableRapport from "pages/Soutenance/DatatableRapport"
// jury
import Jury from "pages/Jury/Jury"
import EditJury from "pages/Jury/EditJury"
// Encadreur
import Encadreur from "../pages/Encadreur/Encadreur"
import EditEncadreur from "../pages/Encadreur/EditEncadreur"
// gestion demande
import GestDemEtud from "../pages/DemandeStage/DemandeStage"
//Planification
import Planification from "pages/PlanificationSoutenance/DataTablePlanification"
import EditPlanifSoutenance from "pages/PlanificationSoutenance/EditPlanifSoutenance"
//NoteSoutenance
import NoteSoutenance from "../pages/NoteSoutenance/DataTableNoteSoutenance"
import EditNoteSoutenance from "../pages/NoteSoutenance/EditNoteSoutenance"
//listDiplomer
import DataTabelListDiplomer from "../pages/ListDiplomer/DataTabelListDiplomer"
// annuaire enseignant
import AnnuaireEnseignant from "../pages/AnnuaireEnseignant/DataTableAnnuaireEns"
// entreprise
import Entreprise from "../pages/Entreprise/Entreprise"
import DetailEntreprise from "../pages/Entreprise/DetailEntreprise"
// demande de stage
import DemandeStage from "../pages/DemandeStage/DemandeStage"
//
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  // profile
  { path: "/profile", component: UserProfile },
  // service stage
  { path: "/OffreStage", component: DataTableOffreStage },
  // affectation stage
  { path: "/AffectStage", component: AffectStage },
  { path: "/EditAffectStage", component: EditAffectStage },
  // soutenance
  { path: "/SoutenanceEtudiant", component: DataTableSoutenance },
  { path: "/Rapport", component: DatatableRapport },
  // Jury
  { path: "/Jury", component: Jury },
  { path: "/EditJury", component: EditJury },
  // Encadreur
  { path: "/Encadreur", component: Encadreur },
  { path: "/EditEncadreur", component: EditEncadreur },
  // gestion demande
  { path: "/GestDemEtud", component: GestDemEtud },
  // NoteSoutenance
  { path: "/NoteSoutenance", component: NoteSoutenance },
  { path: "/EditNoteSoutenance", component: EditNoteSoutenance },
  // listDiplomer
  { path: "/ListDiplome", component: DataTabelListDiplomer },
  // planification
  { path: "/Planification", component: Planification },
  { path: "/EditPlanifSoutenance", component: EditPlanifSoutenance },
  // annuaire enseignant
  { path: "/AnnuaireEnseignant", component: AnnuaireEnseignant },
  // entreprise
  { path: "/Entreprise", component: Entreprise },
  { path: "/DetailEntreprise", component: DetailEntreprise },
  // demande de stage
  { path: "/DemandeStage", component: DemandeStage },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
