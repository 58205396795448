import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import APID from "../../apiD"

const DemandeStage = props => {
  // Data Table
  const [demandeStage, setDemandeStage] = useState([])
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  //
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: demandeStage.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: demandeStage.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "name",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_stage",
      text: props.t("Type de stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "langue",
      text: props.t("Langue"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: props.t("Action"),
      isDummyField: true,
      text: props.t("Action"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat == 0 ? (
          <i
            style={{ color: "#143F6B", cursor: "pointer" }}
            onClick={() => toggle(row.id)}
            className="fas fa-cogs"
          ></i>
        ) : row.etat == 2 ? (
          <i
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => toggle(row.id)}
            className="fas fa-times"
          ></i>
        ) : (
          <i
            style={{ color: "#2ca67a", cursor: "pointer" }}
            className="fas fa-check"
          ></i>
        ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await APID.get("etudiant/demande/list_demande_stage").then(
      res => {
        setDemandeStage(res.data.Demandes)
        setLoading(true)
      }
    )
  }, [])

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const valide = async () => {
    const res = await APID.post("etudiant/demande/changement_etat", {
      id: id,
      etat: 1,
    }).then(res => {
      setModal(!modal)
      const resD = APID.get("etudiant/demande/list_demande_stage").then(
        resD => {
          setDemandeStage(resD.data.Demandes)
          setLoading(true)
        }
      )
    })
  }

  const refuse = async () => {
    const res = await APID.post("etudiant/demande/changement_etat", {
      id: id,
      etat: 2,
    }).then(res => {
      setModal(!modal)
      const resD = APID.get("etudiant/demande/list_demande_stage", {
        id: 1,
      }).then(resD => {
        setDemandeStage(resD.data.Demandes)
        setLoading(true)
      })
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Demande de stage")}</title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <div>
              <Card>
                <CardBody>
                  <React.Fragment>
                    <div>
                      <Row>
                        <Col xs="12">
                          <ToolkitProvider
                            keyField="id"
                            data={demandeStage}
                            columns={Columns()}
                            search
                            bootstrap4
                          >
                            {toolkitProps => (
                              <div>
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <BootstrapTable
                                  wrapperClasses="table-responsive"
                                  noDataIndication={() => <NoDataIndication />}
                                  striped={false}
                                  bordered={false}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"table-light"}
                                  hover
                                  pagination={paginationFactory(pageOptions)}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Col>
                      </Row>
                    </div>
                    <Modal isOpen={modal} toggle={toggle} centered={true}>
                      <div
                        className="modal-header"
                        style={{
                          width: "100% !important",
                          justifyContent: "center !important",
                          margin: "0 auto",
                          fontSize: "17px",
                          fontWeight: "bold",
                        }}
                        toggle={toggle}
                      >
                        {props.t("Validation demande de stage")}
                      </div>
                      <ModalBody>
                        <div
                          style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                          }}
                        >
                          <div
                            className="hvr-push"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginBottom: "15px",
                            }}
                          >
                            <div>
                              <Button
                                className="btn btn-success"
                                onClick={valide}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    paddingRight: "5px",
                                  }}
                                  className="fas fa-check-circle"
                                ></i>
                                {props.t("Valider")}
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="btn btn-danger"
                                onClick={refuse}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    paddingRight: "5px",
                                  }}
                                  className="fas fa-ban"
                                ></i>
                                {props.t("Refuser")}
                              </Button>
                            </div>
                            <div>
                              <Button
                                onClick={toggle}
                                className="btn btn-warning"
                              >
                                <i
                                  style={{
                                    color: "white",
                                    paddingRight: "5px",
                                  }}
                                  className="fas fa-times-circle"
                                ></i>
                                {props.t("Annuler")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                  </React.Fragment>
                </CardBody>
              </Card>
            </div>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DemandeStage))
DemandeStage.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
