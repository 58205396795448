import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import { set } from "lodash"
const EditEncadreur = props => {
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  //
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    //
    setId(id)
    //
    const res = await API.post("encadreur/encadreur_byid", { id }).then(res => {
      setFirstName(res.data.encadreur_byid.firstName)
      setLastName(res.data.encadreur_byid.lastName)
      setPhone(res.data.encadreur_byid.telephone)
      setEmail(res.data.encadreur_byid.email)
      setLoading(true)
    })
  }, [])
  const edit = async () => {
    setLoading(false)
    const res = await API.post("encadreur/update_encadreur", {
      id: id,
      firstName: firstName,
      lastName: lastName,
      telephone: phone,
      email: email,
    },
    )
      .then(res => {
        props.history.push("/Encadreur")
      })
      .catch(() => {
        toast.error(":sens_interdit: Veuillez vérifier tous les champs !", {
          containerId: "A",
        })
      })
  }
  return (
    <React.Fragment>
      {loading ? (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4" style={{ color: "#556ee6" }}>
                    {props.t("Modification encadreur")}
                  </CardTitle>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Nom")} 
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            defaultValue={firstName}
                            onChange={e => setLastName(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Prénom")} 
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            defaultValue={lastName}
                            onChange={e => setFirstName(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Téléphone")} 
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("Email")} 
                          </Label>
                          <Input
                            lg="3"
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Encadreur")}
                        >
                          {props.t("Annuler")}
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
              transition={Slide}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </Container>
        </div>
      ) : (
        <div style={{ marginTop: "8%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            {" "}
            {props.t("load_page")}
          </h4>
        </div>
      )}
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditEncadreur))
EditEncadreur.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
