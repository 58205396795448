import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"

import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { MetaTags } from "react-meta-tags"

const DataTablePlanification = props => {
  const [affectStage, setAffectStage] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(async () => {
    var lang = localStorage.getItem("I18N_LANGUAGE")
    const res = await API.get("affectation/list").then(res => {
      setAffectStage(res.data.Sujet)
      setLoading(true)
    })
  }, [])
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: affectStage.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: affectStage.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "encadrant",
      text: props.t("Encadrant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etudidant",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "stage",
      text: props.t("Sujet"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etat_stage",
      text: props.t("Type de stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Etat",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat_sout == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "green", cursor: "pointer" }}
              className="fas fa-check"
              title="Soutenance fixé"
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "orange", cursor: "pointer" }}
              className="fas fa-hourglass-half"
              title="Soutenance pas encore fixé"
            ></i>
          </div>
        ),
    },
    {
      dataField: "Soutenance",
      isDummyField: true,
      text: props.t("Soutenance"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/EditPlanifSoutenance?id=" + row.id}>
            <i
              style={{
                color: "cornflowerblue",
                cursor: "pointer",
                fontSize: "15px",
              }}
              className="fas fa-user-graduate"
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  // const rowEvent = {
  //   onClick: async (e, row) => {
  //     row.count == 0
  //       ? props.history.push(
  //           "/EditAffectStage?id=" +
  //             row.id +
  //             "/" +
  //             row.id_etudiant +
  //             "/" +
  //             row.id_encadrant
  //         )
  //       : null
  //   },
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Planification")}</title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <Row>
              <Col xs="12">
                <ToolkitProvider
                  keyField="id"
                  data={affectStage}
                  columns={Columns()}
                  search
                  bootstrap4
                >
                  {toolkitProps => (
                    <div>
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication={() => <NoDataIndication />}
                        striped={false}
                        bordered={false}
                        // rowEvents={rowEvent}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"table-light"}
                        hover
                        pagination={paginationFactory(pageOptions)}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTablePlanification))
DataTablePlanification.propTypes = {
  affectStage: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
