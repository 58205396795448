import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import axios from "axios"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Container,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { MetaTags } from "react-meta-tags"
import Select from "react-select"

const DataTableNoteSoutenance = props => {
  var lng = localStorage.getItem("I18N_LANGUAGE")
  //
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [anneeScolaire, setAnneeScolaire] = useState([])
  const [selectAnneeScolaire, setSelectAnneeScolaire] = useState("")
  const [arraySout, setArraySout] = useState([])
  // Data Table
  useEffect(async () => {
    const res = await API.get("soutenance/list", {
      lang: lng,
    }).then(res => {
      setArraySout(res.data.Soutenance)
      setLoading(true)
    })
  }, [])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: arraySout.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: arraySout.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )

  const { SearchBar } = Search

  const Columns = () => [
    {
      dataField: "etudiant",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "stage",
      text: props.t("Stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etat_stage",
      text: props.t("Type"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Etat",
      text: props.t("Etat"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {row.note == "" ? (
            <i
              onClick={() => toggle(row.id)}
              style={{ cursor: "pointer", color: "orange" }}
              className="fas fa-hourglass-half"
              title="Note pas encore attribué"
            ></i>
          ) : (
            <i
              style={{ cursor: "pointer", color: "green" }}
              className="fas fa-check"
              title="Note attribué"
            ></i>
          )}
        </div>
      ),
    },
    {
      dataField: "Note",
      isDummyField: true,
      text: props.t("Note"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.etat == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/EditNoteSoutenance?id=" + row.soutenance_id}>
              <i
                style={{
                  color: "cornflowerblue",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                className="fas fa-pencil-alt"
              ></i>
            </Link>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{
                color: "rgb(167, 163, 163)",
                cursor: "pointer",
                fontSize: "15px",
              }}
              className="fas fa-pencil-alt"
              title="Soutenance pas encore validée"
            ></i>
          </div>
        ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> {props.t("Note soutenance")}</title>
        </MetaTags>
        {loading ? (
          <Container fluid>
            <Row>
              <Col xs="12">
                <ToolkitProvider
                  keyField="id"
                  data={arraySout}
                  columns={Columns()}
                  search
                  bootstrap4
                >
                  {toolkitProps => (
                    <div>
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication={() => <NoDataIndication />}
                        striped={false}
                        bordered={false}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"table-light"}
                        hover
                        pagination={paginationFactory(pageOptions)}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {props.t("load_page")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableNoteSoutenance))
DataTableNoteSoutenance.propTypes = {
  arraySout: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
